.app {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.range {
  display: grid;
  grid-template: repeat(13, 2.25rem) / repeat(13, 2.25rem);
  font-weight: 500;
}

.hand {
  display: flex;
  user-select: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
}

.selected {
  background-color: green;
}

.pair {
  background-color: palegoldenrod;
}

.offsuit {
  background-color: orange;
}

.suited {
  background-color: lawngreen;
}

input.shortrange {
  width: 80%;
  margin-bottom: 5px;
  margin-right: auto;
  text-align: center;
  font-weight: bold;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.control {
  padding: 3px 7px;
  font-size: large;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ml-auto {
  margin-left: auto;
}

.ml-5 {
  margin-left: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mt-auto {
  margin-top: auto;
}

.mr-0 {
  margin-right: 0;
}

.text-center {
  text-align: center;
}

.percent {
  width: 2.5rem;
  height: 1rem;
}

.selector-grid {
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(3, 1fr);
  grid-auto-flow: column;
  gap: 5px;
  justify-items: center;
}

.justify-left {
  justify-self: left;
}

.justify-right {
  justify-self: right;
}

.justify-center {
  justify-self: center;
}
